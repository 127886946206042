<template>
  <div class="api-error-page">
    <h1>Não foi possível conectar a API {{apiURL}}</h1>
  </div>
</template>

<script>
  import Api from '../resources/NajaSocialApi'

  export default {
    computed: {
      apiURL () {
        return Api.baseURL;
      }
    }
  }
</script>
